<template>
  <div class="row justify-content-center">
    <customer-header />
    <vs-prompt color="success" title="" accept-text="Export Excel" cancel-text="ยกเลิก" @accept="exportExcelReport"
      @close="close" :active.sync="activePromptExport">
      <div class="con-exemple-prompt">
        Export Report Excel
        <br /><br />
        <datepicker placeholder="วันที่เริ่มต้น เวลาเข้า" :format="DatePickerFormat" v-model="startDateReport"
          :click="onClickDate()"></datepicker>
        <br />
        <datepicker placeholder="วันที่สิ้นสุด เวลาเข้า" :format="DatePickerFormat" v-if="startDateReport"
          v-model="endDateReport" :disabledDates="disabledDates"></datepicker>
        <br />
        <p style="color: red" align="center">
          Export ได้มากที่สุดแค่ 31 วัน จากวันเริ่มต้น
        </p>
      </div>
    </vs-prompt>

    <vs-prompt color="primary" title="" accept-text="ค้นหา" cancel-text="ยกเลิก" @accept="listItemsSearch(1)"
      @close="close" :active.sync="activePromptSearch">
      <div class="con-exemple-prompt">
        ค้นหาข้อมูลรถเข้า-ออก

        <br /><br />
        <v-select label="text" placeholder="กรุณาเลือก" v-model="selected" @input="checkOption" :options="optionsLists"
          :dir="$vs.rtl ? 'rtl' : 'ltr'" />

        <vs-input v-if="inputSearch === 1" type="text" icon="search" placeholder="คำค้นหา" v-model="searchText" />
        <vs-input v-if="inputSearch === 2" type="date" icon="event" v-model="searchText" />
      </div>
    </vs-prompt>

    <vs-prompt color="primary" title="" accept-text="ตกลง" cancel-text="ยกเลิก" @accept="checkPwd()" @close="close"
      :active.sync="activePromptLogin">
      <div class="con-exemple-prompt">
        เพื่อความปลอดภัยของข้อมูล กรุณากรอกรหัสผ่าน

        <br /><br />
        <vs-input type="password" placeholder="รหัสผ่าน" v-model="password" />
      </div>
    </vs-prompt>

    <vs-prompt color="primary" title="" button-accept="false" accept-text="ตกลง" cancel-text="ยกเลิก" @accept="close"
      @close="close" :active.sync="activePromptRequestData">
      <div class="con-exemple-prompt" style="text-align:center;">
        <h5>จากพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 เพื่อความปลอดภัยของข้อมูลส่วนบุคคล
          หากท่านต้องการดูรายละเอียด กรุณากรอกข้อมูลเพื่อใช้เป็นหลักฐานในการดำเนินการ<br /><br /><a target="_blank"
            href="https://forms.gle/4HzZ9JRRgGJ99dEJ6"><b>กดเพื่อดำเนินการขอดูข้อมูลบัตรประชาชน</b></a></h5>
        <br /><br />
      </div>
    </vs-prompt>

    <vs-popup class="holamundo" title="รายละเอียดข้อมูลรถเข้า-ออก" :active.sync="activePromptDetail"
      v-if="objectDetail">
      <div class="vx-row">
        <div class="vx-col lg:w-1/1 w-full">
          <vx-card class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">เวลาเข้า</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.date_in | formatDate }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เวลาออก</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.date_out | formatDate }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ประเภทรถ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.car_type | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เลขที่ติดต่อ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.contact_address_name | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ทะเบียนรถ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.license_plate_id | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ตราประทับ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.stamp_type | checkString }}
                </td>
              </tr>
              <tr v-if="customer.enable_fee === true">
                <td class="font-semibold">ค่าจอดรวม</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.sum_amount | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เวลาจอด</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.date_full | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">บัตรหาย</td>
                <td style="padding-left: 50px" v-if="objectDetail.date_out !== null">
                  {{ objectDetail.card_missing | cardMissing }}
                </td>
                <td style="padding-left: 50px" v-else>-</td>
              </tr>
              <tr>
                <td class="font-semibold">วัตถุประสงค์</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.visit_objective_type | checkString }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold">ชื่อรูปที่บันทึก</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.id_card_photo | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">หมายเลขเข้า</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.ticket_no | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">แจ้งปิดบ้าน</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.is_close_home | convertCloseHome }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เหตุผลปิดบ้าน</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.close_home_reason | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">แขกวีไอพี</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.is_vip | convertVip }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">หมายเลขใบเสร็จ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.bill_no | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ใช้สิทธิ์</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.use_coupon | checkString }}
                </td>
              </tr>

              <tr :key="indextr" v-for="(field, indextr) in additionFields">
                <td class="font-semibold">{{ field.report_field }}</td>
                <td style="padding-left: 50px" v-if="field.valueField.indexOf('idcard') === -1 || is_god == '1'">
                  <div style="
                                        border: 1px solid #3f4652;
                                        padding: 6px;
                                        background-color: #6b7585;
                                        margin-top: 10px;
                                      " v-if="Array.isArray(objectDetail[field.valueField])">
                    <div style="margin-top: 6px" :key="indextr_image" v-for="(image, indextr_image) in objectDetail[
        field.valueField
      ]">
                      <img v-img style="width: 120px" :src="photoList[image]" class="img-fluid" :key="indextr_image" />
                    </div>
                  </div>
                  <span v-if="!Array.isArray(objectDetail[field.valueField])">
                    {{ objectDetail[field.valueField] | checkString }}
                  </span>
                </td>
                <td style="padding-left: 50px" v-if="field.valueField.indexOf('idcard') !== -1 && is_god == '0'">
                  <div>
                    <h5>จากพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                      เพื่อความปลอดภัยของข้อมูลส่วนบุคคล หากท่านต้องการดูรายละเอียด
                      กรุณากรอกข้อมูลเพื่อใช้เป็นหลักฐานในการดำเนินการ<br /><br /><a target="_blank"
                        href="https://forms.gle/4HzZ9JRRgGJ99dEJ6"><b>กดเพื่อดำเนินการขอดูข้อมูลบัตรประชาชน</b></a>
                    </h5>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">id</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.key | checkString }}
                </td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
    </vs-popup>
    <vs-list>
      <vs-list-item class="k-nav-bar" title="ข้อมูลรถเข้า-ออก" subtitle="">
      </vs-list-item>
    </vs-list>
    <div style="background-color:#FFFFFF;padding: 15px;border-radius: 10px;">
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <v-select label="text" placeholder="กรุณาเลือกประเภท" v-model="selected" @input="checkOption"
            @change="enableSelectTypeDate($event)" :searchable="false" hide-no-data
            style="width: 100%;padding-right: 10px;" :options="optionsLists" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </vs-col>
        <vs-col vs-type="flex" class="input-search" vs-justify="center" vs-align="center" vs-w="2"
          v-if="selected && selected.value == 'contact_address_name'">
          <!-- <template lang="html">
            <div class="centerx"> -->
          <vs-input placeholder="เลขที่บ้าน" v-model="option_text_search" />
          <!-- </div>
          </template> -->
        </vs-col>
        <vs-col vs-type="flex" class="input-search" vs-justify="center" vs-align="center" vs-w="2"
          v-if="selected && selected.value == 'license_plate_id'">
          <!-- <template lang="html">
            <div class="centerx"> -->
          <vs-input placeholder="ทะเบียนรถ" v-model="option_text_search" />
          <!-- </div>
          </template> -->
        </vs-col>
        <vs-col vs-type="flex" class="input-search" vs-justify="center" vs-align="center" vs-w="2"
          v-if="selected && selected.value == 'ticket_no'">
          <!-- <template lang="html">
            <div class="centerx"> -->
          <vs-input placeholder="หมายเลขเข้า" v-model="option_text_search" />
          <!-- </div>
          </template> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="enableTypeDate">
          <v-select label="text" placeholder="กรุณาเลือกรูปแบบวัน" v-model="selectTypeDate" @input="checkOption"
            :searchable="false" hide-no-data style="width: 100%;padding-right: 10px;" :options="selectDateSearch"
            :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="enableDateSearch">
          <!-- <template lang="html">
            <div class="centerx"> -->
          <datepicker id="start-date-report" placeholder="กรุณาเลือกวันที่เริ่มต้น" :format="DatePickerFormat"
            v-model="startDateReport" style="width: 100%;padding-right: 10px;" :click="onClickDate"></datepicker>
          <!-- </div>
          </template> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="enableDateSearch">
          <datepicker id="end-date-report" placeholder="กรุณาเลือกถึงวันที่สิ้นสุด" :format="DatePickerFormat"
            v-model="endDateReport" style="width: 100%;" :disabledDates="disabledDates"></datepicker>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center" class="pt-2" v-if="enableDateSearch">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <v-select label="text" placeholder="กรุณาเลือกประเภทรถ" v-model="selectedCarType" @input="checkOption"
            :searchable="false" hide-no-data style="width: 100%;padding-right: 10px;" :options="optionsListsCarType"
            :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="enableDateSearch">
          <v-select label="text" placeholder="กรุณาเลือกวัตถุประสงค์" v-model="selectedsVisitObjectiveType"
            @input="checkOption" :searchable="false" hide-no-data style="width: 100%;padding-right: 10px;"
            :options="optionsVisitObjectiveType" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="enableDateSearch">
          <v-select label="text" placeholder="กรุณาเลือกรูปแบบตราประทับ" v-model="selectedStampType"
            @input="checkOption" style="width: 100%;" :searchable="false" hide-no-data :options="optionsStampType"
            :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-offset="4" v-tooltip="'col - 2'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <div class="demo-inline-spacing">
            <vs-button @click="listItemsStream" color="primary" type="filled" icon-pack="feather" icon="icon-search"
              :disabled="enableSearch" style="margin-left: 5px;margin-top: 10px;">
              ค้นหา
            </vs-button>
            <vs-button v-if="enableReset" @click="resetSearch" color="dark" type="filled" icon-pack="feather"
              style="margin-left: 5px;margin-top: 10px;">
              Reset
            </vs-button>
            <vs-button :loading="downloadLoading" @click="exportExcelReport" color="success" type="filled"
              :disabled="enableSearch" style="margin-left: 5px;margin-top: 10px;">
              <feather-icon icon="DownloadIcon" title="Export Excel"
                svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
              Export
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <vs-card style="margin-top: 10px">
      <div>
        <vs-table stripe :data="items">
          <template slot="thead" style="margin-top: 100px">
            <vs-th>เวลาเข้า</vs-th>
            <vs-th>เวลาออก</vs-th>
            <vs-th>หมายเลขเข้า</vs-th>
            <vs-th>หมายเลขใบเสร็จ </vs-th>
            <vs-th>ประเภทรถ</vs-th>
            <vs-th>เลขที่ติดต่อ</vs-th>
            <vs-th>ทะเบียนรถ</vs-th>
            <vs-th>ตราประทับ</vs-th>
            <vs-th v-if="customer.enable_fee === true">ค่าจอดรวม</vs-th>
            <vs-th>เวลาจอด</vs-th>
            <vs-th>บัตรหาย</vs-th>
            <vs-th>วัตถุประสงค์</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].date_in">
                {{ data[indextr].date_in | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].date_out">
                {{ data[indextr].date_out | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].ticket_no">
                {{ data[indextr].ticket_no | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].bill_no">
                {{ data[indextr].bill_no | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].car_type">
                {{ data[indextr].car_type | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].contact_address_name">
                {{ data[indextr].contact_address_name | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].license_plate_id">
                {{ data[indextr].license_plate_id | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].stamp_type">
                {{ data[indextr].stamp_type | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].sum_amount" v-if="customer.enable_fee === true">
                {{ data[indextr].sum_amount | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].date_full">
                {{ data[indextr].date_full | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].card_missing" v-if="data[indextr].date_out !== null">
                {{ data[indextr].card_missing | cardMissing }}
              </vs-td>
              <vs-td v-else> - </vs-td>
              <vs-td :data="data[indextr].visit_objective_type">
                {{ data[indextr].visit_objective_type | checkString }}
              </vs-td>
              <vs-td>
                <feather-icon icon="EyeIcon" title="ดูข้อมูลเพิ่มเติม"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="openDetail(data[indextr])" />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div>
          <br />

          <vs-row>
            <vs-col class="text-right">
              <div class="btn-group">
                <vs-button type="border" style="padding-top: 2px" :disabled="page == 1"
                  @click="previousData">Prev</vs-button>
                <vs-button type="border" :disabled="items.length < show_per_page" @click="nextData">Next</vs-button>
              </div>
            </vs-col>
          </vs-row>

          <!--
        <paginate
          v-model="page"
          :page-count="total"
          :page-range="3"
          :margin-pages="2"
          :click-handler="inputData"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate> -->
        </div>
      </div>
    </vs-card>
    <vs-card style="margin-top: 5px">
      <!-- <h5>จำนวนข้อมูลทั้งหมด {{ totalItems }}</h5> -->
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from 'vuex'
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../store/firebaseDb";
import ExcelJS from 'exceljs';
// import { firebaseApp } from "../store/firebaseDb";
import { promises as fs } from 'fs';
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
console.log("lang", lang);

import Paginate from "vuejs-paginate";
import { functions } from "firebase";
import vSelect from "vue-select";
import VueImg from "v-img";
import { instances } from "chart.js";
const crypto = require('crypto');

Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);

const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: false,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
  // Event listener to open gallery will be applied to <img> element
  openOn: "click",
  // Show thumbnails for all groups with more than 1 image
  thumbnails: false,
};

Vue.use(VueImg, vueImgConfig);

Vue.component("customer-header", CustomerHeader);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});
Vue.filter("formatDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
});
Vue.filter("formatOnlyDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY");
  }
  return "-";
});
Vue.filter("cardMissing", function (value) {
  if (value === false) {
    return "มีบัตร";
  } else {
    return "บัตรหาย";
  }
});
Vue.filter("convertCloseHome", function (value) {
  if (value === true) return "ปิดบ้าน";
  return "-";
});
Vue.filter("convertVip", function (value) {
  if (value === true) return "วีไอพี";
  return "-";
});
Vue.filter("checkString", function (value) {
  if (value) return value;
  return "-";
});
console.log(Vue.moment().locale());
export default {
  name: "ManageTransactions",
  components: {},
  data() {
    return {
      key: "1000060000000000",
      roleInfo: {},
      is_god: "0",
      userInfo: null,
      language: "en",
      languages: lang,
      houseInfo: {},
      unsubscribeStream: null,
      items: [],
      photoList: [],
      // pagination
      total: 0, // จำนวนหน้า
      show_per_page: 10,
      page: 1,
      items2: [],
      // totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      // lastItem: null,
      searchText: "",
      password: "",
      activePromptSearch: false,
      activePromptDetail: false,
      activePromptLogin: false,
      activePromptRequestData: false,
      selected: null,
      optionsLists: [
        { text: "รายการรถเข้า-ออก", value: "date_in" },
        { text: "รายการที่ทำรายได้", value: "trans_status" },
        { text: "รายการรถคงค้าง", value: "not_date_out" },
        { text: "เลขที่บ้าน", value: "contact_address_name" },
        { text: "ทะเบียนรถ", value: "license_plate_id" },
        { text: "หมายเลขขาเข้า", value: "ticket_no" },

      ],
      inputSearch: 0,
      objectDetail: null,
      activePromptExport: false,
      startDateReport: "",
      endDateReport: "",
      DatePickerFormat: "dd/MM/yyyy",
      dateStart: null,
      disabledDates: {},
      itemsReport: [],
      additionFields: [],
      customer: {},
      headerTitle: [
        "วันที่เข้า",
        "วันที่ออก",
        "เวลาเข้า",
        "เวลาออก",
        "ประเภทรถ",
        "เลขที่ติดต่อ",
        "ทะเบียนรถ",
        "แจ้งปิดบ้าน",
        "เหตุผลปิดบ้าน",
        "ตราประทับ",
        "ค่าจอดรวม",
        "ระยะเวลา",
        "คูปอง",
        "วัตถุประสงค์",
        "หมายเลขเข้า",
        "หมายเลขใบเสร็จ",
        "บัตรหาย",
        "แขกวีไอพี",
        "id",
      ],
      headerVal: [
        "date_only_in",
        "date_only_out",
        "date_in",
        "date_out",
        "car_type",
        "contact_address_name",
        "license_plate_id",
        "is_close_home",
        "close_home_reason",
        "stamp_type",
        "sum_amount",
        "date_full",
        "use_coupon",
        "visit_objective_type",
        "ticket_no",
        "bill_no",
        "card_missing",
        "is_vip",
        "key",
      ],
      selectDateSearch: [
        { text: "จากวันที่รถเข้า", value: "date_in" },
        { text: "จากวันรถออก", value: "date_out" }
      ],
      selectTypeDate: '',
      downloadLoading: false,
      lastItemsList: {},
      limit: 0,
      selectedCarType: "",
      optionsListsCarType: [],
      selectedsVisitObjectiveType: "",
      optionsVisitObjectiveType: [],
      selectedLicensePlateId: "",
      optionsLicensePlateId: [],
      selectedStampType: "",
      optionsStampType: [],
      advanceSearch: false,
      option_text_search: '',
      option_search: ["license_plate_id", "ticket_no", "contact_address_name"],
      enableTypeDate: false,
      // enableExport:true,

    };
  },
  computed: {
    validName() {
      return true;
    },
    enableSearch() {

      if (this.startDateReport && !this.endDateReport) {
        return true;
      } else if (!this.startDateReport && this.endDateReport) {
        return true;
      }

      if ((this.startDateReport && this.endDateReport) && !this.selected) {
        console.log('test 01')
        // this.enableExport = false;
        return false;
      }


      if (this.selected && this.option_search.includes(this.selected.value) && this.option_text_search != '') {
        console.log('test 02');
        // this.enableExport = true;
        return false;
      }
      if (!this.selected) {
        console.log('test 03')
        return true;
      }

      if (this.selected && this.startDateReport && this.endDateReport) {
        // this.advanceSearch = true;
        console.log('if')
        // this.enableExport = false;
        return false
      } else {
        console.log('else')
        return true;
      }

    },
    changeInput() {
      console.log('changeInput');
      // this.enableExport = true;
      return true;
    },
    enableReset() {
      if (this.selected) {
        return true;
      }

      if (this.option_text_search != '') {
        return true;
      }

      if (this.startDateReport) {
        return true;
      }

      if (this.selectTypeDate) {
        return true;
      }

      if (this.selectedCarType) {
        return true;
      }

      if (this.selectedsVisitObjectiveType) {
        return true;
      }

      if (this.selectedStampType) {
        return true;
      }

      // this.enableExport = true;
      return false;
    },
    enableDateSearch() {
      if (this.selected && this.selected.value === 'ticket_no') {
        this.startDateReport = "";
        this.endDateReport = "";
        // this.enableExport = true;
        return false;
      } else {
        return true;
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    enableSelectTypeDate(event) {

      if (this.selected && this.option_search.includes(this.selected.value) && this.selected.value != 'ticket_no') {
        console.log('enableSelectTypeDate in if')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.enableTypeDate = true;
      } else {
        console.log('enableSelectTypeDate out if')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectTypeDate = '';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.enableTypeDate = false;
        // this.enableExport = true;
      }



    }

  },

  methods: {

    checkString(str) {
      let isBinary = false;
      for (let i = 0; i < str.length; i++) {
        if (str[i] === "0" || str[i] === "1") {
          isBinary = true;
        } else {
          isBinary = false;
        }
      }
    },

    decrypt(cipherText, key, outputEncoding = "utf8") {

      console.log("[[decrypt]] key=" + key);
      console.log("[[decrypt]] outputEncoding=" + outputEncoding);
      console.log("[[decrypt]] cipherText=" + cipherText);
      const initialization_vector = "X05IGQ5qdBnIqAWD";

      const cipher = crypto.createDecipheriv("aes-128-ecb", key, initialization_vector);
      return Buffer.concat([cipher.update(cipherText), cipher.final()]).toString(outputEncoding);

    },

    async parseFile(url) {
      // console.log("[[parseFile]] url=" + url);
      try {

        const fetchResponse = await fetch(url);
        //  const ct = fetchResponse.headers.get("Content-Type");
        // console.log("ct=", ct);
        // const hd = fetchResponse.header();
        // console.log("hd=",hd);
        let responseText = await fetchResponse.text();
        if (!fetchResponse.ok) {
          responseText = "";
        }
        //  if ( !this.checkString(responseText) ) {
        //   console.log("responseText=", responseText);
        //     responseText = "";
        //  }

        //  console.log("responseText=" + responseText);
        return responseText;

      } catch (ex) {
        console.log("Error in fetch");
        return "";
      }
    },

    async loadImage(valueField, img) {
      // console.log("[[getImage]] valueField="+valueField+", img="+img);
      const dcrypted = await this.parseFile(img);
      //  console.log("dcrypted=", dcrypted);
      const photoBase64 = "data:image/png;base64," + dcrypted;
      // console.log("photoBase64=", photoBase64);
      //  this.photoList[img] = photoBase64;
      // return;
      if (dcrypted !== "") {

        try {
          const initialization_vector = "X05IGQ5qdBnIqAWD";
          const encryption_key = "byz9VFNtbRQM0yBODcCb1lrUtVVH3D3x";
          const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(encryption_key), Buffer.from(initialization_vector))
          let dec = decipher.update(dcrypted, 'hex', 'utf8')
          dec += decipher.final('utf8')
          // console.log("Decrypted dec:", dec);
          const photoBase64 = "data:image/jpeg;base64," + dec;
          this.photoList[img] = photoBase64;
        } catch (e) {
          // console.log(dcrypted);
          //  console.error(e);
        }

      }
    },
    dbRef() {
      const customerName = $cookies.get("customer");
      const _ducumentPath = `backup/${customerName}/transactions`;
      // console.log(_ducumentPath);
      return db.collection(_ducumentPath);
      // return db.collection('backup').doc(customerName).collection('transactions');
    },
    dbRefFilter() {
      const customerName = $cookies.get("customer");
      return db.collection('customer').doc(customerName)
    },

    async getCarType() {
      const reference = this.dbRefFilter().collection('car_type');
      const result = await reference.get();
      console.log(result);
      result.forEach((item) => {
        console.log(item.id)
        console.log(item)
        this.optionsListsCarType.push({ text: item.id, value: 'car_type' })
      })
    },
    async getStamp() {
      const reference = this.dbRefFilter().collection('stamp');
      const result = await reference.get();
      console.log(result);
      result.forEach((item) => {
        console.log(item.id)
        console.log(item)
        this.optionsStampType.push({ text: item.id, value: 'stamp_type' })
      })
    },
    async getObjective() {
      const reference = this.dbRefFilter().collection('objective');
      const result = await reference.get();
      console.log(result);
      result.forEach((item) => {
        console.log(item.id)
        console.log(item)
        this.optionsVisitObjectiveType.push({ text: item.id, value: 'visit_objective_type' })
      })
    },
    async getVip() {
      const reference = this.dbRefFilter().collection('vip');

      const result = await reference.get();
      console.log('get Vip')
      console.log(result)
      result.forEach((item) => {
        console.log(item.id)
        console.log(item)
        this.optionsListsCarType.push({ text: "VIP", value: 'car_type' })
        return true
      })
    },
    async getVisitObjectiveType() {
      const reference = this.dbRefFilter();

      const result = await reference.get();
      console.log('getVisitObjectiveType');
      if (!result.data().addition_fields) {
        this.getCarType();
      }
      console.log(result.data())
      const addition_fields = JSON.parse(result.data().addition_fields)
      console.log('addition_fields.fields_in')
      console.log(addition_fields.fields_in)
      for (const addition of addition_fields.fields_in) {
        switch (addition.valueField) {
          // eslint-disable-next-line no-case-declarations
          case 'car_type':
            console.log(addition)
            const collection_arr_car_type = JSON.parse(addition.collection_arr);
            collection_arr_car_type.forEach((item) => {
              this.optionsListsCarType.push({ text: item, value: addition.valueField })
            })
            break;
          // eslint-disable-next-line no-case-declarations
          case 'visit_objective_type':
            const collection_arr_cay = JSON.parse(addition.collection_arr);
            collection_arr_cay.forEach((item) => {
              this.optionsVisitObjectiveType.push({ text: item, value: addition.valueField })
            })
            break;
        }
      }
      console.log('addition_fields.fields_out')
      console.log(addition_fields.fields_out)
      for (const addition_out of addition_fields.fields_out) {
        if (addition_out.valueField == 'stamp_type') {
          const collection_arr = JSON.parse(addition_out.collection_arr);
          collection_arr.forEach((item) => {
            this.optionsStampType.push({ text: item, value: addition_out.valueField })
          })
        }
      }


      if (this.optionsListsCarType && this.optionsListsCarType.length == 0) {
        console.log('test')
        this.getCarType();
      }
      console.log(this.optionsStampType)
      if (this.optionsStampType && this.optionsStampType.length == 0) {
        console.log('test')
        this.getStamp();
      }
      if (this.optionsVisitObjectiveType && this.optionsVisitObjectiveType.length == 0) {
        console.log('test')
        this.getObjective();
      }
      this.getVip();
      // result.forEach((item)=>{
      //   console.log(item);
      // })
    },

    getDbRefWithCondition() {
      console.log("[[getDbRefWithCondition]]");
      let reference = this.getCondition(this.dbRef())
      // reference = reference.orderBy("date_in", "desc");
      if (this.selected && this.selected.value == 'trans_status') {
        console.log('--->20')
        return reference
      }
      const lastItem = this.lastItemsList[`${this.page - 1}`];

      if (lastItem !== undefined) {
        console.log('--->21')
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
      }


      if (this.show_per_page !== null) {


        reference = reference.limit(this.show_per_page);
        console.log("limit => " + this.show_per_page);
        console.log(".limit")
      }

      console.log("reference => ", reference);
      return reference;
    },
    getConditionReport() {
      const reference = this.getCondition(this.dbRef())

      return reference;
    },
    getCondition(reference) {

      if (this.selected && this.selected.value == 'date_in') {
        console.log('--->1')
        console.log('date in')
        // reference = reference.where("trans_status", "==", 2);
        if (this.startDateReport) {
          console.log('--->2')

          console.log('between date time')
          console.log(this.betweenDateTime()[0])
          console.log('"date_in",">=",' + this.betweenDateTime()[0])
          reference = reference.where("date_in", ">=", this.betweenDateTime()[0])
        }

        if (this.endDateReport) {

          console.log('--->3')

          console.log('"date_in","<",' + this.betweenDateTime()[1])
          console.log(this.betweenDateTime()[1]);
          reference = reference.where("date_in", "<", this.betweenDateTime()[1])
        } else if (this.startDateReport) {
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          date.setDate(date.getDate() + 31);
          reference = reference.where("date_in", "<=", date)
        }
        reference = reference.orderBy("date_in", "desc");
      } else if (this.selected && this.selected.value == 'trans_status') {
        console.log('--->4')

        reference = reference.where("trans_status", "==", 2);

        if (this.startDateReport) {
          console.log('--->5')

          console.log('between date time')
          console.log(this.betweenDateTime()[0])
          console.log('"date_out",">=",' + this.betweenDateTime()[0])
          reference = reference.where("date_out", ">=", this.betweenDateTime()[0])
        }

        if (this.endDateReport) {
          console.log('--->6')

          console.log('"date_out","<",this.betweenDateTime()[1]')
          console.log('"date_out","<",' + this.betweenDateTime()[1])
          reference = reference.where("date_out", "<", this.betweenDateTime()[1])
        }


        reference = reference.orderBy("date_out", "desc");
      } else if (this.selected && this.selected.value == 'not_date_out') {
        console.log('--->7')

        reference = reference.where("trans_status", "==", 1);

        if (this.startDateReport) {
          console.log('--->8')

          console.log('between date time')
          console.log(this.betweenDateTime()[0])
          console.log('"date_in",">=",' + this.betweenDateTime()[0])
          reference = reference.where("date_in", ">=", this.betweenDateTime()[0])
        }

        if (this.endDateReport) {
          console.log('--->9')

          console.log('"date_in" endDateReport,"<=",' + this.betweenDateTime()[1]);
          reference = reference.where("date_in", "<=", this.betweenDateTime()[1])
        } else if (this.startDateReport) {
          console.log('--->10')

          const date = new Date();
          date.setHours(0, 0, 0, 0);
          date.setDate(date.getDate() + 31);
          reference = reference.where("date_in", "<=", date)
        }

        reference = reference.orderBy("date_in", "desc");
      } else if (this.selected && this.option_search.includes(this.selected.value) && this.selected.value != 'ticket_no') {
        console.log('--->11')

        console.log('this.selectTypeDate');
        console.log(this.selectTypeDate);
        if (this.startDateReport && this.endDateReport && this.selectTypeDate) {
          console.log('--->12')

          reference = reference.where(this.selectTypeDate.value, '>=', this.betweenDateTime()[0])
          reference = reference.where(this.selectTypeDate.value, '<=', this.betweenDateTime()[1])
          reference = reference.orderBy(this.selectTypeDate.value, "desc");

        } else if (this.startDateReport && this.endDateReport) {
          console.log('--->13')

          reference = reference.where('date_in', '>=', this.betweenDateTime()[0])
          reference = reference.where('date_in', '<=', this.betweenDateTime()[1])
          reference = reference.orderBy('date_in', "desc");

        } else if (this.selectTypeDate) {
          console.log('--->14')

          if (this.selectTypeDate.value == 'date_out') {
            console.log('--->15')


            reference = reference.where('trans_status', '>', 1)
            reference = reference.orderBy('trans_status', "asc");

          } else {

            reference = reference.orderBy("date_in", "desc");

          }

        } else if (!this.selectTypeDate) {
          console.log('--->16')

          reference = reference.orderBy("date_in", "desc");
        } else {
          console.log('--->17')

          reference = reference.orderBy("date_in", "desc");
        }


        // if (!this.startDateReport && !this.endDateReport && !this.selectTypeDate) {
        //   reference = reference.orderBy('date_in', "desc");
        // }

      } else if (!this.selected) {
        if (this.startDateReport) {
          console.log('between date time')
          console.log(this.betweenDateTime()[0])
          console.log('"date_in",">=",' + this.betweenDateTime()[0])
          reference = reference.where("date_in", ">=", this.betweenDateTime()[0])
        }

        if (this.endDateReport) {
          console.log('"date_in","<", ' + this.betweenDateTime()[1])
          reference = reference.where("date_in", "<", this.betweenDateTime()[1])
        } else if (this.startDateReport) {
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          date.setDate(date.getDate() + 1);
          reference = reference.where("date_in", "<=", date)
          reference = reference.orderBy("date_in", "desc");
        }

        reference = reference.orderBy("date_in", "desc");
      } else {
        if (this.startDateReport) {
          console.log('between date time')
          console.log(this.betweenDateTime()[0])
          console.log('"date_in",">=",' + this.betweenDateTime()[0])
          reference = reference.where("date_in", ">=", this.betweenDateTime()[0])
        }

        if (this.endDateReport) {
          console.log('"date_in","<", ' + this.betweenDateTime()[1])
          reference = reference.where("date_in", "<", this.betweenDateTime()[1])
        } else if (this.startDateReport) {
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          date.setDate(date.getDate() + 1);
          reference = reference.where("date_in", "<=", date)
        }

        reference = reference.orderBy("date_in", "desc");
      }


      if (this.selected && this.option_search.includes(this.selected.value)) {
        reference = reference.where(this.selected.value, '==', this.option_text_search)
      } else {
        this.option_text_search = '';
      }

      if (this.selectedCarType) {
        console.log('selectedCarType')
        console.log(this.selectedCarType)
        reference = reference.where(this.selectedCarType.value, '==', this.selectedCarType.text)
      }

      if (this.selectedsVisitObjectiveType) {
        reference = reference.where(this.selectedsVisitObjectiveType.value, '==', this.selectedsVisitObjectiveType.text)
      }
      if (this.selectedStampType) {
        console.log('this.selectedStampType');
        console.log(this.selectedStampType)
        reference = reference.where(this.selectedStampType.value, '==', this.selectedStampType.text)
      }
      // reference = reference.orderBy("date_in", "desc");

      return reference;
    },

    async listItemsStream({ page = 1 }) {

      // this.enableExport = false;
      this.isLoad = true;
      this.page = page;
      console.log("PAGE [" + page + "] this page = " + this.page);
      //disble next page

      if (this.selected && this.selected.value == 'trans_status') {
        this.show_per_page = this.items.length + 1;
      } else {
        this.show_per_page = 10;
      }

      let reference = this.getDbRefWithCondition();
      console.log("reference2=", reference);
      this.items = [];

      this.unsubscribeStream = await reference.onSnapshot(async (snapshotChange) => {
      
        let itemsTemp = []

        this.isLoad = false;
        console.log("snapshotChange=", snapshotChange);

        await snapshotChange.forEach((doc) => {
          console.log("doc.data()=", doc.data());
          this.lastItemsList[`${page}`] = doc;
          const element = this.addElemet(doc);
          itemsTemp.push(element);
        });

        this.items = itemsTemp;
        console.log("this.items=", this.items);

      });
    },
    getdataListItemStream() {

    },
    addElemet(doc) {
      let date_full = "";
      if (doc.data().date_in && doc.data().date_out) {
        date_full = this.strDate(
          doc.data().date_in.seconds,
          doc.data().date_out.seconds
        );
      }

      let element = {
        key: doc.id,
        addition_remark: this.isEmty(doc.data().addition_remark),
        bill_no: this.isEmty(doc.data().bill_no),
        ticket_no: this.isEmty(doc.data().ticket_no),
        car_type: this.isEmty(doc.data().car_type),
        card_missing: doc.data().card_missing,
        card_mission_amount: this.isEmty(doc.data().card_mission_amount),
        close_home_reason: this.isEmty(doc.data().close_home_reason),
        contact_address_name: this.isPrefixAddress(
          doc.data().prefix_address,
          doc.data().contact_address_name
        ),
        date_in: this.isEmty(doc.data().date_in),
        date_out: doc.data().date_out,
        date_full: this.isEmty(date_full),
        device_id: this.isEmty(doc.data().device_id),
        id_card_photo: this.isEmty(doc.data().id_card_photo),
        idcard_photo_name: this.isEmty(doc.data().idcard_photo_name),
        is_close_home: this.isEmty(doc.data().is_close_home),
        is_vip: doc.data().is_vip,
        license_plate_id: doc.data().license_plate_id,
        seq: doc.data().seq,
        stamp_type: this.isEmty(doc.data().stamp_type),
        sum_amount: this.isEmty(doc.data().sum_amount),
        sum_total_time: doc.data().sum_total_time,
        ticket_no: doc.data().ticket_no,
        use_coupon: doc.data().use_coupon,
        visit_objective_type: doc.data().visit_objective_type,
        addition_fields_value: doc.data().addition_fields_value,
      };


      element = this.getAdditionFieldValue(element);
      return element

    },

    isEmty(data) {
      return (data) ? data : '-'
    },

    isPrefixAddress(prefix_address, contact_address_name) {
      return prefix_address ?
        prefix_address + " / " + contact_address_name :
        contact_address_name;
    },

    isEmpty(data) {
      return data === undefined || data === null || data === "";
    },

    previousData: function () {
      this.page = this.page - 1;
      this.listItemsStream({ page: this.page });
    },

    nextData: function () {
      this.page = this.page + 1;
      this.listItemsStream({ page: this.page });
    },

    inputData: function (value) {
      this.page = value;
      this.listItemsStream({ page: this.page });
    },

    close() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
    },


    checkOption() {
      this.searchText = "";
      if (this.selected && this.selected.value === "date_in") {
        // this.enableExport = true;
        this.inputSearch = 2;
      } else {
        // this.enableExport = true;
        this.inputSearch = 1;
      }
    },
    betweenDateTime() {
      // console.log("betweenDateTime startDateReport = "+this.startDateReport)
      // console.log("betweenDateTime endDateReport = "+this.endDateReport)
      const date_formatS = new Date(this.startDateReport);
      const date_formatE = new Date(this.endDateReport);
      const date_inS = new Date(date_formatS - 60 * 60 * 1000);
      const date_inE = new Date(date_formatE - 60 * 60 * 1000);
      date_inS.setHours(0, 0, 0, 0);
      const value = date_inS;
      date_inE.setDate(date_inE.getDate() + 1);
      date_inE.setHours(0, 0, 0, 0);
      const value2 = date_inE;
      // console.log('betweenDateTime value => '+value);
      // console.log('betweenDateTime value2 => '+value2);
      // console.log(
      //   "setDateValue TYPE[" + type + "] s=" + value + " e=[" + value2 + "]"
      // );
      return [value, value2];
    },

    setDateValue(type) {
      const dateStart = type === "excel" ? this.startDateReport : this.searchText;
      const dateEnd = type === "excel" ? this.endDateReport : this.searchText;
      const date_formatS = new Date(dateStart);
      const date_formatE = new Date(dateEnd);
      const date_inS = new Date(date_formatS - 60 * 60 * 1000);
      const date_inE = new Date(date_formatE - 60 * 60 * 1000);
      date_inS.setHours(0, 0, 0, 0);
      const value = date_inS;
      date_inE.setDate(date_inE.getDate() + 1);
      date_inE.setHours(0, 0, 0, 0);
      const value2 = date_inE;
      // console.log(
      //   "setDateValue TYPE[" + type + "] s=" + value + " e=[" + value2 + "]"
      // );
      return [value, value2];
    },

    getExportFileName() {
      const customerName = $cookies.get("customer");
      const startDate = moment(new Date(this.startDateReport)).format(
        "DDMMYYYY"
      );
      const endDate = moment(new Date(this.endDateReport)).format("DDMMYYYY");
      return customerName + "-" + startDate + "-" + endDate;
    },

    strDate(begin, end) {
      const d = Math.trunc((end - begin) / 60 / 60 / 24);
      const h = Math.trunc((end - begin) / 60 / 60) % 24;
      const m = Math.trunc((end - begin) / 60) % 60;
      const s = (end - begin) % 60;
      let msg = "";
      if (d > 0) {
        msg += d + " วัน ";
      }
      if (h > 0) {
        msg += h + " ชั่วโมง ";
      }
      if (m > 0) {
        msg += m + " นาที ";
      }
      if (s > 0) {
        msg += s + " วินาที ";
      }
      if (s <= 0) {
        msg += s + " วินาที ";
      }
      // console.log("strDate TIME DAY["+d+"]HOUR["+h+"]minute["+m+"]second["+s+"] = "+msg);
      return msg;
    },

    async resetSearch() {
      this.startDateReport = "";
      this.endDateReport = ""
      this.selected = null;
      this.searchText = "";
      this.items = [];
      this.total = 0;
      this.page = 1;
      this.isLoad = true;
      this.total = 0;
      this.selectedCarType = "";
      this.selectedsVisitObjectiveType = "";
      this.selectedLicensePlateId = "";
      this.selectedStampType = "";
      this.option_text_search = '';
      this.enableTypeDate = false;
      this.selectTypeDate = '';
      // this.totalItems = 0;
      // this.lastItem = null;
      this.selected = "";
      this.listItemsStream(1);
    },

    openDetail(objectData) {
      this.objectDetail = null;

      // this.activePromptLogin = true;
      this.activePromptDetail = true;
      this.objectDetail = objectData;

    },

    openRequestData(objectData) {
      //  const is_god = $cookies.get("is_god");
      if (this.is_god === "1") {
        this.openDetail(objectData);
      } else {
        this.objectDetail = null;
        this.activePromptRequestData = true;
        this.objectDetail = objectData;
      }
    },

    checkPwd() {

      const pawd = Vue.$cookies.get("pawd");

      console.log("pawd=" + pawd);
      console.log("password=" + this.password);

      if (pawd === this.password) {
        this.activePromptDetail = true;
        this.password = "";
      } else {
        this.$vs.notify({
          color: "danger",
          title: "แจ้งเตือน",
          text: "รหัสผ่านไม่ถูกต้อง",
        });
      }
    },
    async getCustomer() {
      const customerName = $cookies.get("customer");
      const customerRef = db.collection("customer").doc(customerName);

      await customerRef.get().then((cusInfo) => {
        this.customer = cusInfo.data();
        this.prepareAdditionFields();
      });
    },

    prepareAdditionFields() {
      console.log("[[prepareAdditionFields]]");
      const addition_fields = this.customer.addition_fields_report;
      console.log("addition_fields_report=", addition_fields);

      if (!this.isEmpty(addition_fields)) {
        const addition_fields_obj = JSON.parse(addition_fields);

        this.additionFields = addition_fields_obj;
      }

      console.log("[[this.additionFields]]=", this.additionFields);
    },

    getAdditionFieldValue(item) {
      // console.log("[[getAdditionFieldValue]] item", item);

      const addition_fields_value = item.addition_fields_value;

      // console.log("addition_fields_value=" + addition_fields_value);

      if (this.isEmpty(addition_fields_value)) {
        return item;
      }

      // console.log("getAdditionFieldValue 11");

      const addition_fields_value_obj = JSON.parse(addition_fields_value);

      // console.log("getAdditionFieldValue 22");

      if (this.isEmpty(addition_fields_value_obj)) return item;

      // console.log("getAdditionFieldValue 33");
      // console.log("getAdditionFieldValue 44", this.additionFields);

      for (let index = 0; index < this.additionFields.length; index++) {
        const element = this.additionFields[index];
        // console.log("element.valueField=" + element.valueField);

        let value = addition_fields_value_obj[element.valueField];
        // console.log("value=", value);
        //v-if="field.valueField.indexOf('idcard') === -1 || is_god == '1'">
        if (this.isEmpty(value)) {
          item[element.valueField] = "-";
          continue;
        }

        value = value.value;
        // console.log("value2=" , value);

        const valueField = element.valueField.toLowerCase();
        // const indexOf = valueField.indexOf("attach");
        console.log(">>>valueField=" + valueField);

        if (value instanceof Array) {
          const photoList = value;
          for (let i = 0; i < photoList.length; i++) {
            const photoUrl = photoList[i];
            this.photoList[photoUrl] = photoUrl;
            // if("addition_in_attach_idcard" == valueField ){
            this.loadImage(valueField, photoUrl);
            //  }
          }
        }

        if (this.isEmpty(value)) {
          item[element.valueField] = "-";
          continue;
        }

        item[element.valueField] = value;
      }

      return item;
    },

    openModalExcel() {
      this.startDateReport = null;
      this.endDateReport = null;
      this.activePromptExport = true;
    },

    async onClickDate() {
      if (this.startDateReport) {
        if (this.startDateReport !== this.dateStart) {
          this.dateStart = this.startDateReport;
          this.endDateReport = null;
          // this.enableExport = true;
          this.disabledDates = {
            to: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000) - 8640000
            ),
            from: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000).setDate(
                new Date(this.startDateReport - 60 * 60 * 1000).getDate() + 31
              ) - 8640000
            ),
          };
        }
      }
    },

    async exportExcelReport() {
      this.downloadLoading = true;
      this.$vs.loading()
      this.getConditionReport().get().then(async (query) => {
        this.itemsReport = [];
        let sum_amount = 0;
        await query.forEach(async (item) => {
          if ((this.selected && this.selected.value == 'trans_status') && item.data().sum_amount == 0) {

          }
          else if ((this.selected && this.selected.value == 'trans_status') && item.data().sum_amount > 0) {
            const element = this.addElemet(item)
            sum_amount += item.data().sum_amount;
            this.itemsReport.push(element);
          } else if (!this.selected || (this.selected && this.selected.value != 'trans_status')) {
            const element = this.addElemet(item)
            sum_amount += item.data().sum_amount;
            this.itemsReport.push(element);
          }

        })
        if (this.itemsReport.length) {
          if (this.additionFields.length > 0) {
            for (let index = 0; index < this.additionFields.length; index++) {
              const element = this.additionFields[index];
              if (this.is_god === "1" && this.headerVal.indexOf(element.valueField) === -1) {
                this.headerTitle.push(element.report_field);
                this.headerVal.push(element.valueField);
              } else if (element.valueField.indexOf("idcard") === -1) {
                this.headerTitle.push(element.report_field);
                this.headerVal.push(element.valueField);
              }
            }
          }

          if (this.customer.enable_fee === false) {
            this.headerTitle = this.headerTitle.filter((res) => {
              return res !== "ค่าจอดรวม";
            });
            this.headerVal = this.headerVal.filter((res) => {
              return res !== "sum_amount";
            });
          }
          await this.downloadExcel();
          this.$vs.loading.close();
          console.log("EXPORT EXCEL");
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "warning",
            title: "Export Excel Report",
            text: "ไม่พบข้อมูล !",
          });

        }
      });
      this.$vs.loading.close()
    },
    async downloadExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');
      let headerLabel = [];
      this.headerVal.forEach((item, index) => {
        console.log(item)
        let maxLength = 15
        let cellLength = this.headerTitle[index].length;
        if (cellLength > maxLength) {
          maxLength = cellLength;
        }
        const header = {
          header: this.headerTitle[index],
          key: item,
          width: maxLength
        }
        headerLabel.push(header);
      });
      console.log(headerLabel);
      console.log(this.itemsReport);
      // Add some data to the worksheet
      worksheet.columns = headerLabel;
      worksheet.getRow(1);
      // Apply styles to the header row
      let headerRow = worksheet.getRow(1);
      headerRow.height = 30;
      headerRow.eachCell((cell) => {

        cell.font = {
          bold: true,
          color: { argb: 'FFFFFFFF' },
          size: 14
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000FF' }
        };
      });

      let sum_amount_total = 0;
      for (let [index, item] of this.itemsReport.entries()) {
        if (item.sum_amount && item.sum_amount != '-') {
          sum_amount_total += item.sum_amount;
        }
        const date_in = new Date(item.date_in.seconds * 1000);

        // worksheet.addRow(item);
        item.date_only_in = moment(String(date_in)).format("DD/MM/YYYY")
        item.date_in = moment(String(date_in)).format("HH:mm:ss")
        const temp_data = item;
        if (item.date_out) {
          const date_out = new Date(item.date_out.seconds * 1000);
          item.date_only_out = moment(String(date_out)).format("DD/MM/YYYY")
          item.date_out = moment(String(date_out)).format("HH:mm:ss")

        }
        item.addition_in_attach_idcard = "";
        console.log(headerLabel.length);
        let row = worksheet.addRow(item);
        row.height = 20;
        //  Apply styles to the row
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            // pattern:'solid',
            // stops: [
            //   {position:0, color:{argb:'FFFF0000'}},
            //   {position:1, color:{argb:'FF00FF00'}}
            // ]
          }
        });

        let jsonData = (temp_data.addition_fields_value) ? JSON.parse(temp_data.addition_fields_value) : null;
        if (this.is_god === "1" && jsonData && jsonData.addition_in_attach_idcard) {
          const column = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
          const selectColumn = headerLabel.length / 24;
          console.log(column[(headerLabel.length - 1)]);
          let columnName = "";
          if (selectColumn > 0) {
            columnName = column[1];
            columnName += column[selectColumn];

          } else {
            columnName = column[19];
          }

          const dcrypted = await this.parseFile(jsonData.addition_in_attach_idcard.value[0]);
          if (dcrypted !== "") {
            try {
              console.log(dcrypted);
              const initialization_vector = "X05IGQ5qdBnIqAWD";
              const encryption_key = "byz9VFNtbRQM0yBODcCb1lrUtVVH3D3x";
              const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(encryption_key), Buffer.from(initialization_vector))
              let dec = decipher.update(dcrypted, 'hex', 'utf8')
              dec += decipher.final('utf8')
              // add image to workbook by base64
              const myBase64Image = "data:image/jpg;base64," + dec;
              const imageId2 = workbook.addImage({
                base64: myBase64Image,
                extension: 'jpg',
              });
              const indexRow = index + 2;
              const field = `${column[(headerLabel.length - 1)]}${indexRow}:${column[(headerLabel.length - 1)]}${indexRow}`;
              console.log('field')
              console.log(field)
              worksheet.addImage(imageId2, field);
            } catch (e) {
              console.error(e);
            }
          }

        }

      }

      let rowSum = this.itemsReport.length + 3;
      var rowValues = [];
      rowValues[1] = `รายการทั้งหมด`;
      rowValues[3] = `${this.itemsReport.length}`;
      rowValues[4] = `รายการ`;

      if (this.customer.enable_fee === true) {
        rowValues[9] = `ยอดเงินทั้งหมด`;
        rowValues[10] = ` ${sum_amount_total} บาท`;
      }
      const totalAmountWS = `J${rowSum}:K${rowSum}`
      // rowValues[11] = dcrypted;
      const countRowWS = `A${rowSum}:B${rowSum}`;
      let rowTotalAmount = worksheet.insertRow(rowSum, rowValues);
      rowTotalAmount.height = 20;
      worksheet.mergeCells(countRowWS);
      worksheet.getCell(countRowWS).alignment = { vertical: 'bottom', horizontal: 'center' };
      worksheet.getCell(countRowWS).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };

      if (this.customer.enable_fee === true) {
        worksheet.mergeCells(totalAmountWS);
        worksheet.getCell(totalAmountWS).alignment = { vertical: 'bottom', horizontal: 'right' };
        worksheet.getCell(totalAmountWS).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
      worksheet.autoFilter = {
        from: 'A1',
        to: 'Y1',
      }
      // for(const itemRow )
      // worksheet.addRow({id: 1, name: 'John Doe', dob: new Date(1970, 1, 1)});

      // Generate the Excel file
      const buffer = await workbook.xlsx.writeBuffer();

      // Create a Blob from the buffer
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a link element
      const link = document.createElement('a');
      const customerName = $cookies.get("customer");
      // Set the href and download attributes of the link
      link.href = URL.createObjectURL(blob);
      link.download = customerName + '.xlsx';

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click on the link
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    },
    isValidUrl(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%_.~+]*)*' + // path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(url);
    },
    arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = [].slice.call(new Uint8Array(buffer));
      bytes.forEach((b) => binary += String.fromCharCode(b));
      return window.btoa(binary);
    },



    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          const reportValue = v[j];
          if (this.isEmpty(reportValue)) return "-";
          if (Array.isArray(reportValue)) return reportValue.join();

          return reportValue;
        })
      );
    },
    openLoading() {
      this.$vs.loading()
      // setTimeout( ()=> {
      //   this.$vs.loading.close()
      // }, 2000);
    },
  },
  components: {
    Datepicker,
    "v-select": vSelect,
  },
  watch: {},
  async created() {
    this.is_god = $cookies.get("is_god");
    this.roleInfo = $cookies.get("roleInfo");
    this.userInfo = $cookies.get("userInfo");
    console.log("[[this.userInfo]]", this.userInfo);

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }
    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);

    await this.getCustomer();
    this.listItemsStream({ page: this.page });
    // this.getCarType();
    this.getVisitObjectiveType();
    // this.listItemsAll();
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}

.vs-input {
  width: 100%;
  margin-top: 0px !important;

}

.page-item {}

.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.vs-input .vs-input--input.normal {
  padding: 7px !important;
}

.vdp-datepicker input {
  padding: 8px !important;
}

.fullscreen-v-img {
  z-index: 999999999 !important;
}

.input-search {
  /* margin-left: 0px; */
  padding-right: 10px !important;
}
</style>
